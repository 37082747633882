import React from 'react';
import styled from 'styled-components';
import BoxImageSized from '../BoxImageSized';
import { sizeFromRow, sizeAsBase, sizeAsMax } from '../../styles/utils';
import { media } from '../../styles';
import { h0 } from '../../styles/typography';
import { useLaxElement } from '../../hooks/useLax';
import * as Scroll from 'react-scroll';

function r(n) {
    return n + 1900;
}

const Scene2_3 = ({
    symptoms_anchor,
    symptoms_title,
    symptoms_text,
    cardiologist_anchor,
    cardiologist_title,
    cardiologist_text_01,
    cardiologist_text_02,
    cardiologist_text_03
}) => {
    const refTitleSymptoms = useLaxElement();
    const refTitleCardiolog = useLaxElement();
    const refBackground = useLaxElement();
    const refBackgroundInside = useLaxElement();
    const refForeground = useLaxElement();
    const refCharacter = useLaxElement();
    const refCharacterEnd = useLaxElement();

    return (
        <Scene id="scene-2_3">
            <BackgroundMultiply
                ref={refBackground}
                data-lax-opacity={`${r(1100)} 1, ${r(1150)} 0`}
                data-lax-translate-y={`${r(0)} 0, ${r(1150)} 1150`}
                src="/images/scene-2-background-outside.jpg"
                ratio={1204 / 760}
            />
            <BackgroundMultiply
                ref={refBackgroundInside}
                data-lax-opacity={`${r(1000)} 0, ${r(1100)} 1, ${r(2600)} 1, ${r(2700)} 0`}
                data-lax-translate-y={`${r(0)} 0, ${r(3100)} 3100`}
                src="/images/scene-2-background-inside.jpg"
                ratio={1204 / 760}
            />
            <Character
                ref={refCharacter}
                data-lax-opacity={`(${r(-10)}-vh) 0, (${r(0)}-vh) 1, ${r(2500)} 1, ${r(2600)} 0`}
                data-lax-translate-y={`${r(0)} 0, ${r(3100)} 3100`}
                data-lax-translate-x={`${r(1100)} 0, ${r(1600)} (elw*0.9)`}
                src="/images/scene-2-patient.png"
                ratio={525 / 731}
            />
            <Background
                ref={refForeground}
                data-lax-opacity={`${r(1400)} 0, ${r(1500)} 1, ${r(2600)} 1, ${r(2700)} 0`}
                data-lax-translate-y={`${r(0)} 0, ${r(3100)} 3100`}
                data-lax-translate-x={`${r(1400)} -50, ${r(1600)} 0`}
                src="/images/scene-2-foreground.png"
                ratio={1204 / 850}
            />

            <Title
                ref={refTitleSymptoms}
                /* eslint-disable-next-line */
                data-lax-opacity={`(${r(-160)}-elh) 0, (${r(-150)}-elh) 1, ${r(4500)} 1, ${r(4501)} 0`}
                data-lax-translate-y={`(${r(0)}-vh*0.5-elh) (-vh*0.5-elh), ${r(0)} 0`}
                dangerouslySetInnerHTML={{ __html: symptoms_title.html }}
            />
            <Rte_2_0 dangerouslySetInnerHTML={{ __html: symptoms_text.html }} />

            <Title
                ref={refTitleCardiolog}
                data-lax-opacity={`${r(1000)} 0, ${r(1200)} 1`}
                data-lax-translate-y={`${r(1000)} 1000, ${r(1600)} 1600`}
                dangerouslySetInnerHTML={{ __html: cardiologist_title.html }}
            />
            <Rte_2_1 dangerouslySetInnerHTML={{ __html: cardiologist_text_01.html }} />

            <CharacterEnd
                ref={refCharacterEnd}
                ratio={726 / 1412}
                data-lax-opacity={`${r(2600)} 0, ${r(2800)} 1, ${r(3900)} 1, ${r(4100)} 0`}
                data-lax-translate-y={`${r(2100)} (-elh*0.5), ${r(4100)} (elh*0.5)`}
                src="/images/scene-0-patient.png"
                role="presentation"
            />

            <Rte_3_2>
                <div dangerouslySetInnerHTML={{ __html: cardiologist_text_02.html }} />
                <BackgroundEnd
                    ratio={1360 / 763}
                    src="/images/scene-0-background.png"
                    role="presentation"
                />
                <div dangerouslySetInnerHTML={{ __html: cardiologist_text_03.html }} />
            </Rte_3_2>

            <Scroll.Element name={symptoms_anchor} className="scroll-element-symptoms" />
            <Scroll.Element name={cardiologist_anchor} className="scroll-element-cardiologist" />
        </Scene>
    );
};

export default Scene2_3;

// Sumup prevScenes = 1260 + 640 = 1900
const Scene = styled.div`
    position: relative;
    height: 4500px;
    overflow: visible;

    .scroll-element-symptoms {
        position: absolute;
        height: 1200px;
        top: 0;
        right: 0;
        left: 0;
    }

    .scroll-element-cardiologist {
        position: absolute;
        top: 1200px;
        right: 0;
        left: 0;
        bottom: 0;
    }
`;

const Background = styled(BoxImageSized)`
    position: absolute;
    top: 0;
    right: ${sizeAsBase(-3)};
    width: ${sizeFromRow(1204)};

    ${media.large`
        right: ${sizeAsMax(-3)};
    `}
`;

const BackgroundMultiply = styled(Background)`
    mix-blend-mode: multiply;
`;

const Character = styled(BoxImageSized)`
    position: absolute;
    margin-top: ${sizeFromRow(225)};
    left: ${sizeFromRow(530)};
    width: ${sizeFromRow(420)};
`;

const Title = styled.div`
    position: absolute;
    top: 150px;
    left: ${sizeAsBase(2)};
    z-index: 2;

    h2 {
        ${h0}
    }

    ${media.large`
        left: ${sizeAsMax(2)};
    `}
`;

const Rte_2_0 = styled.div`
    position: absolute;
    top: 1000px;
    left: ${sizeAsBase(2)};
    width: ${sizeFromRow(360)};

    ${media.large`
        left: ${sizeAsMax(2)};
    `}
`;

const Rte_2_1 = styled.div`
    position: absolute;
    top: 2500px;
    left: ${sizeAsBase(2)};
    width: ${sizeFromRow(360)};

    ${media.large`
        left: ${sizeAsMax(2)};
    `}
`;

const CharacterEnd = styled(BoxImageSized)`
    position: absolute;
    top: 3350px;
    left: ${sizeFromRow(160)};
    width: ${sizeFromRow(390)};
    z-index: 3;
`;

const BackgroundEnd = styled(BoxImageSized)`
    margin-left: -100%;
    margin-top: 15%;
    width: 200%;
`;

const Rte_3_2 = styled.div`
    position: absolute;
    top: 3500px;
    right: ${sizeAsBase(2)};
    width: ${sizeFromRow(460)};

    ${media.large`
        right: ${sizeAsMax(2)};
    `}
`;
