import React from 'react';
import styled from 'styled-components';
import BoxImageSized from '../BoxImageSized';
import { sizeAsBase, sizeAsMax, sizeFromRow } from '../../styles/utils';
import { media } from '../../styles';
import { h0 } from '../../styles/typography';
import { useLaxElement } from '../../hooks/useLax';
import { RteMedicalCare } from '../Rte';

const r = n => n + 6400 + 3500 + 1600 + 3000 + 1600;

const Scene8 = ({ title, text }) => {
    const refIllustration = useLaxElement();
    const refContent = useLaxElement();
    return (
        <Scene>
            <Illustration
                ref={refIllustration}
                data-lax-opacity={`(${r(-10)}-vh) 0, (${r(0)}-vh) 1, ${r(1500)} 1, ${r(1501)} 0`}
                data-lax-translate-y={`(${r(0)}-vh) -vh, ${r(0)} 0, ${r(650)} 650`}
                src="/images/scene-8-illustration.jpg"
                ratio={1160 / 806}
            />
            <Content
                ref={refContent}
                data-lax-opacity={`(${r(-10)}-vh) 0, (${r(0)}-vh) 1, ${r(1500)} 1, ${r(1501)} 0`}
                data-lax-translate-y={`(${r(0)}-vh) -vh, ${r(0)} 0`}
            >
                <div dangerouslySetInnerHTML={{ __html: title.html }} />
                <RteMedicalCare dangerouslySetInnerHTML={{ __html: text.html }} />
            </Content>
        </Scene>
    );
};

export default Scene8;

const Scene = styled.div`
    position: relative;
    height: 1500px;
`;

const Illustration = styled(BoxImageSized)`
    position: absolute;
    top: 0;
    left: ${sizeAsBase(-3)};
    width: ${sizeFromRow(930)};
    mix-blend-mode: multiply;

    ${media.large`
        left: ${sizeAsMax(-3)};
    `}
`;

const Content = styled.div`
    position: absolute;
    top: 150px;
    right: ${sizeAsBase(2)};
    width: ${sizeFromRow(360)};

    h2 {
        ${h0}
        margin-bottom: ${props => props.theme.spacing.s40};
    }

    ${media.large`
        right: ${sizeAsMax(2)};
    `}
`;
