import React from 'react';
import styled from 'styled-components';
import { sizeAsBase, sizeAsMax } from '../../styles/utils';
import { h2 } from '../../styles/typography';
import { useLaxElement } from '../../hooks/useLax';
import { media } from '../../styles';
import { gutterBackground } from '../../styles/others';

const r = n => n + 1260;

const Scene1 = ({ title, text }) => {
    const refTitle = useLaxElement();
    const refText = useLaxElement();
    return (
        <Scene>
            <Title
                ref={refTitle}
                /* eslint-disable-next-line */
                data-lax-opacity={`(${r(0)}-0.5*vh) 0, (${r(200)}-0.5*vh) 1, ${r(640)} 1, ${r(641)} 0`}
                dangerouslySetInnerHTML={{ __html: title.html }}
            />
            <div
                ref={refText}
                /* eslint-disable-next-line */
                data-lax-opacity={`(${r(100)}-0.5*vh) 0, (${r(300)}-0.5*vh) 1, ${r(640)} 1, ${r(641)} 0`}
                dangerouslySetInnerHTML={{ __html: text.html }}
            />
        </Scene>
    );
};

export default Scene1;

const Scene = styled.div`
    position: relative;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding: ${props => props.theme.spacing.s160} ${sizeAsBase(2)} 0;
    background: ${props => props.theme.colors.red};
    height: 640px;
    ${gutterBackground}

    & > div:first-child {
        width: ${sizeAsBase(15)};
    }

    & > div:last-child {
        width: ${sizeAsBase(6 * 3 + 5 * 2)};
    }

    a {
        text-decoration: none;
        color: inherit;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    ${media.large`
        padding-left: ${sizeAsMax(2)};
        padding-right: ${sizeAsMax(2)};

        & > div:first-child {
            width: ${sizeAsMax(15)};
        }

        & > div:last-child {
            width: ${sizeAsMax(6 * 3 + 5 * 2)};
        }
    `}
`;

const Title = styled.div`
    h2 {
        color: ${props => props.theme.colors.white};
        ${h2};
    }
`;
