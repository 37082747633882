import React from 'react';
import styled from 'styled-components';
import Scene0 from './Scene0';
import * as Scroll from 'react-scroll';
import { useLax } from '../../hooks';
import Scene1 from './Scene1';
import Scene2_3 from './Scene2_3';
import Scene4 from './Scene4';
import Scene5 from './Scene5';
import Scene6 from './Scene6';
import Scene7 from './Scene7';
import Scene8 from './Scene8';
import Scene9 from './Scene9';
import Nav from './Nav';

const MedicalCareDesktop = ({
    title_content,
    documents_anchor,
    documents_title,
    documents_text,
    symptoms_anchor,
    symptoms_title,
    symptoms_text,
    cardiologist_anchor,
    cardiologist_title,
    cardiologist_text_01,
    cardiologist_text_02,
    cardiologist_text_03,
    rythmologist_anchor,
    rythmologist_title,
    rythmologist_text,
    hospital_anchor,
    hospital_title,
    hospital_text,
    intervention_anchor,
    intervention_title,
    intervention_text_implantation,
    intervention_text_ablation,
    recovery_anchor,
    recovery_title,
    recovery_text_01,
    recovery_text_02,
    exit_anchor,
    exit_title,
    exit_text,
    end_anchor,
    end_title,
    end_text
}) => {
    useLax();

    const steps = [
        documents_anchor,
        symptoms_anchor,
        cardiologist_anchor,
        rythmologist_anchor,
        hospital_anchor,
        intervention_anchor,
        recovery_anchor,
        exit_anchor,
        end_anchor
    ];

    return (
        <>
            <Scene0 title={title_content} />
            <StyledSteps>
                <Nav steps={steps} />
                <Scroll.Element
                    name={documents_anchor}
                    style={{ marginTop: -60, position: 'relative', zIndex: 5 }}
                >
                    <Scene1 title={documents_title} text={documents_text} />
                </Scroll.Element>
                <Scene2_3
                    symptoms_anchor={symptoms_anchor}
                    symptoms_title={symptoms_title}
                    symptoms_text={symptoms_text}
                    cardiologist_anchor={cardiologist_anchor}
                    cardiologist_title={cardiologist_title}
                    cardiologist_text_01={cardiologist_text_01}
                    cardiologist_text_02={cardiologist_text_02}
                    cardiologist_text_03={cardiologist_text_03}
                />
                <Scroll.Element
                    name={rythmologist_anchor}
                    style={{ position: 'relative', zIndex: 5 }}
                >
                    <Scene4 title={rythmologist_title} text={rythmologist_text} />
                </Scroll.Element>
                <Scroll.Element name={hospital_anchor}>
                    <Scene5 title={hospital_title} text={hospital_text} />
                </Scroll.Element>
                <Scroll.Element
                    name={intervention_anchor}
                    style={{ position: 'relative', zIndex: 5 }}
                >
                    <Scene6
                        title={intervention_title}
                        text_implantation={intervention_text_implantation}
                        text_ablation={intervention_text_ablation}
                    />
                </Scroll.Element>
                <Scroll.Element name={recovery_anchor} style={{ position: 'relative', zIndex: 3 }}>
                    <Scene7
                        title={recovery_title}
                        text_01={recovery_text_01}
                        text_02={recovery_text_02}
                    />
                </Scroll.Element>
                <Scroll.Element name={exit_anchor} style={{ position: 'relative', zIndex: 2 }}>
                    <Scene8 title={exit_title} text={exit_text} />
                </Scroll.Element>
                <Scroll.Element name={end_anchor}>
                    <Scene9 title={end_title} text={end_text} />
                </Scroll.Element>
            </StyledSteps>
        </>
    );
};

export default MedicalCareDesktop;

const StyledSteps = styled.div``;
