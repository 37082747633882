import React from 'react';
import styled, { css } from 'styled-components';
import * as Scroll from 'react-scroll';
import media, { maxWidth } from '../../styles/media';
import { gutterBackground } from '../../styles/others';
import { sizeAsBase, sizeFromRow } from '../../styles/utils';
import { h3 } from '../../styles/typography';
import BorderSeparator from '../BorderSeparator';
import { RteMedicalCare } from '../Rte';
import Nav from './Nav';

const MedicalCareLight = ({
    title_content,
    documents_anchor,
    documents_title,
    documents_text,
    symptoms_anchor,
    symptoms_title,
    symptoms_text,
    cardiologist_anchor,
    cardiologist_title,
    cardiologist_text_01,
    cardiologist_text_02,
    cardiologist_text_03,
    rythmologist_anchor,
    rythmologist_title,
    rythmologist_text,
    hospital_anchor,
    hospital_title,
    hospital_text,
    intervention_anchor,
    intervention_title,
    intervention_text_implantation,
    intervention_text_ablation,
    recovery_anchor,
    recovery_title,
    recovery_text_01,
    recovery_text_02,
    exit_anchor,
    exit_title,
    exit_text,
    end_anchor,
    end_title,
    end_text
}) => {
    const steps = [
        documents_anchor,
        symptoms_anchor,
        cardiologist_anchor,
        rythmologist_anchor,
        hospital_anchor,
        intervention_anchor,
        recovery_anchor,
        exit_anchor,
        end_anchor
    ];

    return (
        <Module>
            <Scene0>
                <Title dangerouslySetInnerHTML={{ __html: title_content.html }} />
                <Picture>
                    <source srcSet="" media={`(max-width: ${maxWidth.mobile}px)`} />
                    <img srcSet="/images/tablet/scene-0.jpg" alt="" />
                </Picture>
            </Scene0>
            <StyledSeparator />
            <Nav steps={steps} />
            <Scroll.Element name={documents_anchor}>
                <Scene1>
                    <Title dangerouslySetInnerHTML={{ __html: documents_title.html }} />
                    <Text dangerouslySetInnerHTML={{ __html: documents_text.html }} />
                </Scene1>
            </Scroll.Element>
            <StyledSeparator />
            <Scroll.Element name={symptoms_anchor}>
                <Scene2>
                    <BlockLeft>
                        <Title dangerouslySetInnerHTML={{ __html: symptoms_title.html }} />
                        <div dangerouslySetInnerHTML={{ __html: symptoms_text.html }} />
                    </BlockLeft>
                    <Picture>
                        <source srcSet="" media={`(max-width: ${maxWidth.mobile}px)`} />
                        <img srcSet="/images/tablet/scene-02.jpg" alt="" />
                    </Picture>
                </Scene2>
            </Scroll.Element>
            <StyledSeparator />
            <Scroll.Element name={cardiologist_anchor}>
                <Scene3>
                    <Title dangerouslySetInnerHTML={{ __html: cardiologist_title.html }} />
                    <Text dangerouslySetInnerHTML={{ __html: cardiologist_text_01.html }} />
                    <Picture>
                        <source srcSet="" media={`(max-width: ${maxWidth.mobile}px)`} />
                        <img srcSet="/images/tablet/scene-03.jpg" alt="" />
                    </Picture>
                    <Text dangerouslySetInnerHTML={{ __html: cardiologist_text_02.html }} />
                    <Text dangerouslySetInnerHTML={{ __html: cardiologist_text_03.html }} />
                    <Picture>
                        <source srcSet="" media={`(max-width: ${maxWidth.mobile}px)`} />
                        <img srcSet="/images/tablet/scene-03-02.jpg" alt="" />
                    </Picture>
                </Scene3>
            </Scroll.Element>
            <StyledSeparator />
            <Scroll.Element name={rythmologist_anchor}>
                <Scene4>
                    <Title dangerouslySetInnerHTML={{ __html: rythmologist_title.html }} />
                    <Picture>
                        <source srcSet="" media={`(max-width: ${maxWidth.mobile}px)`} />
                        <img srcSet="/images/tablet/scene-04.jpg" alt="" />
                    </Picture>
                    <div className="red">
                        <Picture>
                            <source srcSet="" media={`(max-width: ${maxWidth.mobile}px)`} />
                            <img srcSet="/images/tablet/scene-04-heart.jpg" alt="" />
                        </Picture>
                        <Text dangerouslySetInnerHTML={{ __html: rythmologist_text.html }} />
                    </div>
                </Scene4>
            </Scroll.Element>
            <StyledSeparator />
            <Scroll.Element name={hospital_anchor}>
                <Scene5>
                    <Title dangerouslySetInnerHTML={{ __html: hospital_title.html }} />
                    <Picture>
                        <source srcSet="" media={`(max-width: ${maxWidth.mobile}px)`} />
                        <img srcSet="/images/tablet/scene-05.jpg" alt="" />
                    </Picture>
                    <Text dangerouslySetInnerHTML={{ __html: hospital_text.html }} />
                </Scene5>
            </Scroll.Element>
            <StyledSeparator />
            <Scroll.Element name={intervention_anchor}>
                <Scene6>
                    <Title dangerouslySetInnerHTML={{ __html: intervention_title.html }} />
                    <div className="aside">
                        <div>
                            <Picture>
                                <source srcSet="" media={`(max-width: ${maxWidth.mobile}px)`} />
                                <img srcSet="/images/tablet/scene-06-implantation.jpg" alt="" />
                            </Picture>
                        </div>
                        <Text
                            dangerouslySetInnerHTML={{
                                __html: intervention_text_implantation.html
                            }}
                        />
                    </div>
                    <Picture className="pacemaker">
                        <source srcSet="" media={`(max-width: ${maxWidth.mobile}px)`} />
                        <img srcSet="/images/tablet/scene-06-pacemaker.jpg" alt="" />
                    </Picture>
                    <div className="aside">
                        <div>
                            <Picture>
                                <source srcSet="" media={`(max-width: ${maxWidth.mobile}px)`} />
                                <img srcSet="/images/tablet/scene-06-ablation.jpg" alt="" />
                            </Picture>
                        </div>
                        <Text
                            dangerouslySetInnerHTML={{ __html: intervention_text_ablation.html }}
                        />
                    </div>
                </Scene6>
            </Scroll.Element>
            <StyledSeparator />
            <Scroll.Element name={recovery_anchor}>
                <Scene7>
                    <Title dangerouslySetInnerHTML={{ __html: recovery_title.html }} />
                    <Text dangerouslySetInnerHTML={{ __html: recovery_text_01.html }} />
                    <AnimatedExit>
                        <img src="/images/tablet/scene-7-background-hospital.jpg" />
                        <img src="/images/tablet/scene-7-background-room.jpg" />
                        <img src="/images/tablet/scene-7-character.png" />
                    </AnimatedExit>
                    <Text dangerouslySetInnerHTML={{ __html: recovery_text_02.html }} />
                </Scene7>
            </Scroll.Element>
            <StyledSeparator />
            <Scroll.Element name={exit_anchor}>
                <Scene8>
                    <Picture>
                        <source srcSet="" media={`(max-width: ${maxWidth.mobile}px)`} />
                        <img srcSet="/images/tablet/scene-08.jpg" alt="" />
                    </Picture>
                    <BlockRight>
                        <Title dangerouslySetInnerHTML={{ __html: exit_title.html }} />
                        <Text dangerouslySetInnerHTML={{ __html: exit_text.html }} />
                    </BlockRight>
                </Scene8>
            </Scroll.Element>
            <StyledSeparator />
            <Scroll.Element name={end_anchor}>
                <Scene9>
                    <Title dangerouslySetInnerHTML={{ __html: end_title.html }} />
                    <BlockLeft>
                        <Text dangerouslySetInnerHTML={{ __html: end_text.html }} />
                    </BlockLeft>
                    <Picture>
                        <source srcSet="" media={`(max-width: ${maxWidth.mobile}px)`} />
                        <img srcSet="/images/tablet/scene-0.jpg" alt="" />
                    </Picture>
                </Scene9>
            </Scroll.Element>
        </Module>
    );
};

export default MedicalCareLight;

const Module = styled.div`
    img {
        width: 100%;
        height: auto;
    }
`;

const StyledSeparator = styled(BorderSeparator)`
    margin-left: ${sizeAsBase(-3)};
    margin-right: ${sizeAsBase(-3)};
    width: calc(100% + ${sizeAsBase(6)});
`;

const Title = styled.div`
    margin-bottom: ${props => props.theme.spacing.s40};
    h1,
    h2 {
        font-size: 52px;
        line-height: 60px;
        font-weight: ${props => props.theme.fontWeight.light};
        color: ${props => props.theme.colors.blue};
    }
`;

const Text = styled(RteMedicalCare)`
    a {
        text-decoration: none;
        color: inherit;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
`;

const BlockLeft = styled.div`
    margin-right: 50%;

    ${media.mobile`
        margin-right: 0;
    `}
`;

const BlockRight = styled.div`
    margin-left: calc(50% + ${props => props.theme.spacing.s40});

    ${media.mobile`
        margin-left: 0;
    `}
`;

const Scene = styled.div`
    position: relative;
    ${gutterBackground};
    padding: ${props => props.theme.spacing.s40} ${sizeAsBase(2)};
`;

const Picture = styled.picture`
    display: block;
    position: relative;
    mix-blend-mode: multiply;
`;

const gradientBlue = css`
    background: linear-gradient(to bottom, #e5f2ff 0%, #fff 400px);
`;

const gradientYellow = css`
    background: linear-gradient(to bottom, #fff8e2 0%, #fff 400px);
`;

// ------------------------------------------------------------
//    SCENES
// ------------------------------------------------------------
const Scene0 = styled(Scene)`
    ${gradientBlue};
    min-height: ${(542 / 768) * 100 + 12.5}vw;

    ${Picture} {
        position: absolute;
        top: 12.5vw;
        left: ${sizeAsBase(-3)};
        right: ${sizeAsBase(-3)};
    }

    ${media.mobile`
        min-height: ${(542 / 768) * 100 + 60}vw;
        ${Picture} {
            top: 60vw;
        }
    `}
`;

const Scene1 = styled(Scene)`
    padding: ${props => props.theme.spacing.s100} ${sizeAsBase(2)};
    background: ${props => props.theme.colors.red};
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: ${sizeAsBase(2)};
    grid-row-gap: ${props => props.theme.spacing.s40};
    margin-top: -60px;

    ${Title} {
        h1, h2 {
            ${h3}
            color: ${props => props.theme.colors.white};
        }
    }

    ${media.mobile`
        grid-template-columns: 1fr;
    `}
`;

const Scene2 = styled(Scene)`
    ${gradientYellow}
    min-height: ${(542 / 768) * 100 + 10}vw;

    ${Picture} {
        position: absolute;
        top: 10vw;
        left: ${sizeAsBase(-3)};
        right: ${sizeAsBase(-3)};
    }
`;

const Scene3 = styled(Scene)`
    ${gradientBlue};

    ${Picture} {
        margin-left: ${sizeAsBase(-5)};
        margin-right: ${sizeAsBase(-5)};
    }

    ${Title} {
        position: absolute;
        left: ${sizeAsBase(2)};
        top: ${props => props.theme.spacing.s40};
    }

    ${Text} {
        margin-left: calc(50% + ${props => props.theme.spacing.s40});

        & + ${Text} {
            margin-top: ${props => props.theme.spacing.s40};
        }
    }

    ${media.mobile`
        ${Title} {
            position: relative;
            left: auto;
            top: auto;
            margin-bottom: ${props => props.theme.spacing.s40};
        }

        ${Text} {
            margin-left: 0;
        }
    `}
`;
const Scene4 = styled(Scene)`
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;

    ${Title} {
        padding-left: ${sizeAsBase(2)};
        padding-right: ${sizeAsBase(2)};
    }

    & > ${Picture} {
        margin-left: ${sizeAsBase(-3)};
        margin-right: ${sizeAsBase(-3)};
    }

    .red {
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        grid-column-gap: ${props => props.theme.spacing.s40};
        background: ${props => props.theme.colors.red};
        padding: ${props => props.theme.spacing.s40} ${sizeAsBase(2)};
        ${gutterBackground};

        picture {
            display: block;
            mix-blend-mode: unset;
        }

        img {
            border-radius: 50%;
        }
    }

    ${media.mobile`
        .red {
            grid-template-columns: 1fr;
            grid-row-gap: ${props => props.theme.spacing.s40};
        }
    `}
`;
const Scene5 = styled(Scene)`
    ${gradientYellow};

    ${Title} {
        position: relative;
        z-index: 2;
    }

    ${Picture} {
        margin-top: -15vw;
    }
`;
const Scene6 = styled(Scene)`
    .pacemaker {
        width: ${sizeFromRow(510)};
        margin: ${props => props.theme.spacing.s40} ${sizeAsBase(3)};
        img {
            border-radius: 50%;
        }
    }

    .aside {
        display: flex;

        & > div:first-child {
            flex-basis: calc(50% + ${props => props.theme.spacing.s40});

            picture {
                display: block;
                margin-left: ${sizeAsBase(-5)};
            }
        }

        & > div:last-child {
            flex-basis: calc(50% - ${props => props.theme.spacing.s40});
        }
    }

    ${media.mobile`
        .pacemaker {
            width: 80%;
            margin-left: auto;
            margin-right: auto;
        }
        .aside {
            display: block;
        }
    `}
`;
const Scene7 = styled(Scene)`
    ${gradientYellow}

    ${Text} {
        margin-right: calc(50% + ${props => props.theme.spacing.s40});
    }

    ${media.mobile`
        ${Text} {
            margin-right: 0;
        }
    `}
`;
const Scene8 = styled(Scene)`
    min-height: ${(806 / 1160) * 70 + 20}vw;
    ${Picture} {
        position: absolute;
        top: 10vw;
        left: ${sizeAsBase(-5)};
        width: 70%;
    }

    ${media.mobile`
        min-height: initial;

        ${Picture} {
            position: relative;
            top: auto;
            width: auto;
            left: auto;
        }
    `}
`;
const Scene9 = styled(Scene)`
    ${gradientBlue};
`;

const AnimatedExit = styled.div`
    position: absolute;
    top: ${props => props.theme.spacing.s40};
    right: ${sizeAsBase(-3)};
    width: 60%;
    mix-blend-mode: multiply;

    &:before {
        content: '';
        display: block;
        padding-bottom: ${(766 / 1075) * 100}%;
    }

    img {
        position: absolute;
        top: 0;
        left: 0;

        &:nth-child(2) {
            opacity: 0;
        }

        &:last-child {
            left: auto;
            width: ${(380 / 900) * 100}%;
            top: 65%;
            right: 15%;
        }
    }

    ${media.mobile`
        position: relative;
        width: 100%;
        top: auto;
        right: auto;
    `}
`;
