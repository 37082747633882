import React from 'react';
import styled from 'styled-components';
import BoxImageSized from '../BoxImageSized';
import { sizeFromRow, sizeAsBase, sizeAsMax } from '../../styles/utils';
import { h0 } from '../../styles/typography';
import { media } from '../../styles';
import { useLaxElement } from '../../hooks/useLax';
import { gutterBackground } from '../../styles/others';

function r(n) {
    return n + 6400;
}

const Scene4 = ({ title, text }) => {
    const refOverlay = useLaxElement();
    const refBackground = useLaxElement();
    const refCharacter = useLaxElement();
    const refTitle = useLaxElement();
    const refHeart = useLaxElement();

    return (
        <Scene>
            <Background
                ref={refBackground}
                data-lax-opacity={`${r(500)} 0, ${r(1000)} 1, ${r(1500)} 1, ${r(1800)} 0`}
                data-lax-translate-x={`${r(500)} -100, ${r(1500)} 0`}
                data-lax-translate-y={`${r(-1000)} -1000, ${r(2700)} 2700`}
                src="/images/scene-4-background.jpg"
                ratio={1305 / 905}
            />
            <Title
                ref={refTitle}
                data-lax-opacity={`${r(300)} 0, ${r(400)} 1, ${r(1000)} 1, ${r(1250)} 0`}
                data-lax-translate-y={`${r(-1000)} -1000, ${r(1000)} 1000`}
                dangerouslySetInnerHTML={{ __html: title.html }}
            />
            <Character
                ref={refCharacter}
                data-lax-opacity={`${r(0)} 0, ${r(300)} 1, ${r(1500)} 1, ${r(1800)} 0`}
                data-lax-translate-x={`${r(0)} (-elw*2), ${r(1500)} 0`}
                data-lax-translate-y={`${r(-1000)} -1000, ${r(2700)} 2700`}
                src="/images/scene-4-character.png"
                ratio={490 / 771}
            />
            <Overlay ref={refOverlay} data-lax-opacity={`${r(1500)} 0, ${r(1800)} 1`} />
            <Heart
                ref={refHeart}
                src="/images/scene-4-heart.jpg"
                data-lax-opacity={`${r(1490)} 0, ${r(1500)} 1`}
                data-lax-translate-y={`${r(-1000)} -1000, ${r(2700)} 2700`}
                data-lax-scale={`${r(1500)} 0, ${r(1800)} 1`}
                ratio={1}
            />
            <Rte dangerouslySetInnerHTML={{ __html: text.html }} />
        </Scene>
    );
};

export default Scene4;

// Sumup prevScenes = 1900 + 3100 = 5000
// true = 1900 + 4500 = 6400
const Scene = styled.div`
    position: relative;
    height: 3500px;
`;

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: ${props => props.theme.colors.red};
    z-index: 3;
    pointer-events: none;
    ${gutterBackground}
`;

const Background = styled(BoxImageSized)`
    position: absolute;
    top: 0;
    right: 0;
    width: ${sizeFromRow(1045)};
`;

const Character = styled(BoxImageSized)`
    position: absolute;
    top: 0;
    margin-top: ${sizeFromRow(100)};
    right: 20px;
    width: ${sizeFromRow(390)};
    z-index: 2;
`;

const Heart = styled(BoxImageSized)`
    display: block;
    position: absolute;
    margin-top: ${sizeFromRow(100)};
    right: ${sizeFromRow(410)};
    margin-right: ${sizeFromRow(-500 / 2)};
    width: ${sizeFromRow(500)};
    border-radius: 50%;
    overflow: hidden;
    z-index: 4;
`;

const Title = styled.div`
    position: absolute;
    top: 150px;
    left: ${sizeAsBase(2)};

    h2 {
        ${h0}
    }

    ${media.large`
        left: ${sizeAsMax(2)};
    `}
`;

const Rte = styled.div`
    position: absolute;
    top: 2350px;
    left: ${sizeAsBase(2)};
    width: ${sizeFromRow(360)};
    z-index: 4;

    ${media.large`
        left: ${sizeAsMax(2)};
    `}
`;
