import React, { useRef } from 'react';
import * as Scroll from 'react-scroll';
import styled from 'styled-components';
import { theme, media } from '../../styles';
import { sizeAsBase } from '../../styles/utils';
import { useMedia } from '../../hooks';
import { mqTabletLandscape } from '../../hooks/useMedia';

const Nav = ({ steps }) => {
    const $wrapper = useRef();
    const $nav = useRef();
    const isTablet = useMedia(mqTabletLandscape, [true], false);

    const handleActive = key => {
        const index = steps.indexOf(key);
        if (isTablet && $wrapper.current.children[index]) {
            $nav.current.scrollTo($wrapper.current.children[index].offsetLeft, 0, 100);
        }
    };

    return (
        <StyledNav length={steps.length} ref={$nav}>
            <StyledWrapper length={steps.length} ref={$wrapper}>
                {steps.map((title, index) => (
                    <Scroll.Link
                        key={title}
                        to={title}
                        spy={true}
                        offset={-theme.sizes.headerHeight}
                        onSetActive={handleActive}
                    >
                        <span>{index + 1}</span>
                        <p>{title}</p>
                    </Scroll.Link>
                ))}
            </StyledWrapper>
        </StyledNav>
    );
};

export default Nav;

const StyledWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;

    ${media.tabletLandscape`
        width: ${props => 200 * props.length}px;
    `}
`;

const StyledNav = styled.nav`
    position: sticky;
    top: ${props => props.theme.sizes.headerHeight}px;
    margin: 0 ${sizeAsBase(2)};
    height: 60px;
    z-index: 6;

    a {
        width: ${props => 100 / props.length}%;
        font-size: ${props => props.theme.fontSize.small};
        line-height: ${props => props.theme.lineHeight.small};
        font-weight: bold;
        color: ${props => props.theme.colors.grey};
        transition: color 0.3s ${props => props.theme.easings.easeInOutSine};
        text-transform: uppercase;
        cursor: pointer;

        p {
            transition: opacity 0.3s ${props => props.theme.easings.easeInOutSine};
            opacity: 0;
        }

        @media (hover: hover) {
            &:hover {
                p {
                    opacity: 1;
                }
            }
        }

        &.active {
            color: ${props => props.theme.colors.black};
            p {
                opacity: 1;
            }
        }
    }

    ${media.tabletLandscape`
        overflow-x: scroll;
        display: block;
        a {
            float: left;
            width: 200px;
        }
    `}

    ${media.mobile`
        top: ${props => props.theme.sizes.headerHeightMobile}px;
    `}
`;
