import React from 'react';
import styled from 'styled-components';
import BoxImageSized from '../BoxImageSized';
import { sizeFromRow, sizeAsBase, sizeAsMax } from '../../styles/utils';
import { useLaxElement } from '../../hooks/useLax';
import { h0 } from '../../styles/typography';
import { media } from '../../styles';
import { gutterBackground } from '../../styles/others';
import { RteMedicalCare } from '../Rte';

const r = n => n + 6400 + 3500 + 1600 + 3000;

const Scene7 = ({ title, text_01, text_02 }) => {
    const refTitle = useLaxElement();
    const refCharacter = useLaxElement();
    const refBackgroundHospital = useLaxElement();
    const refBackgroundRoom = useLaxElement();
    return (
        <Scene>
            <Title
                ref={refTitle}
                data-lax-opacity={`(${r(-10)}-vh) 0, (${r(0)}-vh) 1, ${r(1600)} 1, ${r(1601)} 0`}
                data-lax-translate-y={`(${r(0)}-vh*0.5) -500, (${r(0)}-vh*0.5+500) 0`}
                dangerouslySetInnerHTML={{ __html: title.html }}
            />
            <Text>
                <RteMedicalCare dangerouslySetInnerHTML={{ __html: text_01.html }} />
                <RteMedicalCare dangerouslySetInnerHTML={{ __html: text_02.html }} />
            </Text>
            <BackgroundRoom
                ref={refBackgroundRoom}
                data-lax-opacity={`${r(400)} 0, ${r(500)} 1, ${r(1600)} 1, ${r(1601)} 0`}
                data-lax-translate-y={`${r(0)} 0, ${r(700)} 700`}
                src="/images/scene-7-background-room.jpg"
                ratio={1075 / 766}
            />
            <BackgroundHospital
                ref={refBackgroundHospital}
                data-lax-opacity={`(${r(0)}-vh-10) 0, (${r(0)}-vh) 1, ${r(450)} 1, ${r(550)} 0`}
                data-lax-translate-y={`${r(0)} 0, ${r(700)} 700`}
                src="/images/scene-7-background-hospital.jpg"
                ratio={1075 / 745}
            />
            <Character
                ref={refCharacter}
                data-lax-opacity={`(${r(-10)}-vh) 0, (${r(0)}-vh) 1, ${r(1600)} 1, ${r(1601)} 0`}
                data-lax-translate-y={`${r(0)} 0, ${r(700)} 700`}
                src="/images/scene-7-character.png"
                ratio={574 / 247}
            />
        </Scene>
    );
};

export default Scene7;

const Scene = styled.div`
    position: relative;
    height: 1600px;
    background: #fff8e1;
    ${gutterBackground}
`;

const Title = styled.div`
    position: absolute;
    top: 150px;
    left: ${sizeAsBase(2)};

    h2 {
        ${h0}
    }

    ${media.large`
        left: ${sizeAsMax(2)};
    `}
`;

const BackgroundHospital = styled(BoxImageSized)`
    position: absolute;
    top: 0;
    right: ${sizeAsBase(-3)};
    width: ${sizeFromRow(900)};
    mix-blend-mode: multiply;

    ${media.large`
        right: ${sizeAsMax(-3)};
    `}
`;

const BackgroundRoom = styled(BoxImageSized)`
    position: absolute;
    top: 0;
    right: ${sizeAsBase(-3)};
    width: ${sizeFromRow(900)};
    mix-blend-mode: multiply;
    opacity: 0;

    ${media.large`
        right: ${sizeAsMax(-3)};
    `}
`;

const Character = styled(BoxImageSized)`
    width: ${sizeFromRow(460)};
    position: absolute;
    margin-top: ${sizeFromRow(380)};
    right: ${sizeFromRow(60)};
`;

const Text = styled.div`
    position: absolute;
    top: 600px;
    left: ${sizeAsBase(2)};
    width: ${sizeFromRow(360)};

    & > div:first-child {
        margin-bottom: ${props => props.theme.spacing.s160};
    }

    ${media.large`
        left: ${sizeAsMax(2)};
    `}
`;
