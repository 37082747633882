import React from 'react';
import styled from 'styled-components';
import { sizeAsBase, sizeAsMax, sizeFromRow } from '../../styles/utils';
import { media } from '../../styles';
import { useLaxElement } from '../../hooks/useLax';
import BoxImageSized from '../BoxImageSized';
import { h0 } from '../../styles/typography';
import { gutterBackground } from '../../styles/others';

const Scene0 = ({ title }) => {
    const ref = useLaxElement();
    const refCharacter = useLaxElement();
    const refBackground = useLaxElement();

    return (
        <Scene>
            <TitleWrapper>
                <Title
                    ref={ref}
                    data-lax-opacity="0 1, 360 1, 460 0"
                    dangerouslySetInnerHTML={{ __html: title.html }}
                />
            </TitleWrapper>
            <Overflow>
                <Background
                    ref={refBackground}
                    ratio={1360 / 763}
                    data-lax-opacity="1265 1, 1270 0"
                    data-lax-translate-y="0 0, 1260 -150"
                    src="/images/scene-0-background.png"
                    role="presentation"
                />
                <Character
                    ref={refCharacter}
                    ratio={726 / 1412}
                    data-lax-opacity="1265 1, 1270 0"
                    data-lax-translate-y="0 0, 1260 200"
                    src="/images/scene-0-patient.png"
                    role="presentation"
                />
            </Overflow>
        </Scene>
    );
};

export default Scene0;

const Scene = styled.div`
    position: relative;
    height: 1260px;
    background: linear-gradient(to bottom, #e5f2ff 0%, #ffffff 30%);
    ${gutterBackground}
`;

const TitleWrapper = styled.div`
    position: absolute;
    top: 0;
    left: ${sizeAsBase(2)};
    height: 860px;

    ${media.large`
        left: ${sizeAsMax(2)};
    `}
`;

const Title = styled.div`
    position: sticky;
    top: ${props => props.theme.sizes.headerHeight + 100}px;

    h1 {
        ${h0}
    }
`;

const Overflow = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
`;

const Background = styled(BoxImageSized)`
    position: absolute;
    top: ${sizeFromRow(400)};
    margin-left: ${sizeFromRow(-180)};
    width: 100%;
`;

const Character = styled(BoxImageSized)`
    position: absolute;
    top: ${sizeFromRow(350)};
    left: ${sizeFromRow(80)};
    width: ${sizeFromRow(580)};
`;
