import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Row from '../components/Row';
import MedicalCareDesktop from '../components/medicalcare/MedicalCareDesktop';
import { useMedia } from '../hooks';
import { mqTabletLandscape } from '../hooks/useMedia';
import MedicalCareLight from '../components/medicalcare/MedicalCareLight';

const PageMedicalCare = ({ data }) => {
    const { seo_title, seo_description } = data.page.data;
    const isTablet = useMedia(mqTabletLandscape, [true], false);
    return (
        <StyledRow>
            <Helmet>
                <title>{seo_title}</title>
                {seo_description && <meta name="description" content={seo_description} />}
            </Helmet>
            {isTablet ? (
                <MedicalCareLight key="tablet" {...data.page.data} />
            ) : (
                <MedicalCareDesktop key="desktop" {...data.page.data} />
            )}
        </StyledRow>
    );
};

export default PageMedicalCare;

export const pageQuery = graphql`
    query PageMedicalCareQuery($lang: String!) {
        page: prismicPageMedicalcare(lang: { eq: $lang }) {
            uid
            data {
                seo_title
                seo_description
                title {
                    html
                }
                title_content {
                    html
                }
                documents_anchor
                documents_title {
                    html
                }
                documents_text {
                    html
                }
                documents_button_text
                symptoms_anchor
                symptoms_title {
                    html
                }
                symptoms_text {
                    html
                }
                cardiologist_anchor
                cardiologist_title {
                    html
                }
                cardiologist_text_01 {
                    html
                }
                cardiologist_text_02 {
                    html
                }
                cardiologist_text_03 {
                    html
                }
                rythmologist_anchor
                rythmologist_title {
                    html
                }
                rythmologist_text {
                    html
                }
                hospital_anchor
                hospital_title {
                    html
                }
                hospital_text {
                    html
                }
                intervention_anchor
                intervention_title {
                    html
                }
                intervention_text_implantation {
                    html
                }
                intervention_text_ablation {
                    html
                }
                recovery_anchor
                recovery_title {
                    html
                }
                recovery_text_01 {
                    html
                }
                recovery_text_02 {
                    html
                }
                exit_anchor
                exit_title {
                    html
                }
                exit_text {
                    html
                }
                end_anchor
                end_title {
                    html
                }
                end_text {
                    html
                }
            }
        }
    }
`;

const StyledRow = styled(Row)`
    position: relative;
    overflow: visible;

    img {
        height: auto;
    }
`;
