import React from 'react';
import styled from 'styled-components';
import { h0 } from '../../styles/typography';
import { sizeAsBase, sizeAsMax, sizeFromRow } from '../../styles/utils';
import { media } from '../../styles';
import { useLaxElement } from '../../hooks/useLax';
import BoxImageSized from '../BoxImageSized';
import { gutterBackground } from '../../styles/others';
import { RteMedicalCare } from '../Rte';

const r = n => n + 6400 + 3500 + 1600 + 3000 + 1600 + 1500;

const Scene9 = ({ title, text }) => {
    const refTitle = useLaxElement();
    const refText = useLaxElement();
    const refBackground = useLaxElement();
    const refCharacter = useLaxElement();

    return (
        <Scene>
            <Title
                ref={refTitle}
                data-lax-opacity={`(${r(-10)}-vh) 0, (${r(0)}-vh) 1, ${r(0)} 1, ${r(100)} 0`}
                dangerouslySetInnerHTML={{ __html: title.html }}
            />
            <Text
                ref={refText}
                data-lax-opacity={`(${r(-10)}-vh) 0, (${r(0)}-vh) 1, ${r(1250)} 1, ${r(1251)} 0`}
                data-lax-translate-y={`(${r(360)}-vh*0.5) 0, (${r(660)}-vh*0.5) 300`}
                dangerouslySetInnerHTML={{ __html: text.html }}
            />
            <Background
                ref={refBackground}
                ratio={1360 / 763}
                data-lax-opacity={`(${r(-10)}-vh) 0, (${r(0)}-vh) 1, ${r(1250)} 1, ${r(1251)} 0`}
                data-lax-translate-y={`${r(-300)} -100, ${r(300)} 100`}
                src="/images/scene-0-background.png"
                role="presentation"
            />
            <Character
                ref={refCharacter}
                ratio={726 / 1412}
                data-lax-opacity={`(${r(-10)}-vh) 0, (${r(0)}-vh) 1, ${r(1250)} 1, ${r(1251)} 0`}
                data-lax-translate-y={`${r(-300)} 100, ${r(300)} -100`}
                src="/images/scene-0-patient.png"
                role="presentation"
            />
        </Scene>
    );
};

export default Scene9;

const Scene = styled.div`
    position: relative;
    height: 1250px;
    background: linear-gradient(to bottom, #e5f2ff 0%, #ffffff 30%);
    ${gutterBackground}
`;

const Title = styled.h2`
    position: relative;
    padding-top: ${props => props.theme.spacing.s110};
    left: ${sizeAsBase(2)};
    margin-bottom: ${props => props.theme.spacing.s60};

    h2 {
        ${h0}
    }

    ${media.large`
        left: ${sizeAsMax(2)};
    `}
`;

const Text = styled(RteMedicalCare)`
    position: absolute;
    top: 360px;
    width: ${sizeFromRow(360)};
    margin-left: ${sizeAsBase(2)};
`;

const Background = styled(BoxImageSized)`
    position: absolute;
    top: ${sizeFromRow(400)};
    right: 0;
    width: ${sizeFromRow(980)};
`;

const Character = styled(BoxImageSized)`
    position: absolute;
    top: ${sizeFromRow(350)};
    left: ${sizeFromRow(400)};
    width: ${sizeFromRow(430)};
`;
