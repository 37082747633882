import React from 'react';
import styled from 'styled-components';
import { h0 } from '../../styles/typography';
import { sizeAsBase, sizeAsMax, sizeFromRow } from '../../styles/utils';
import { media } from '../../styles';
import { useLaxElement } from '../../hooks/useLax';
import BoxImageSized from '../BoxImageSized';
import { RteMedicalCare } from '../Rte';
import { gutterBackground } from '../../styles/others';

const r = n => n + 6400 + 3500 + 1600;

const Scene6 = ({ title, text_implantation, text_ablation }) => {
    const refColorBackground = useLaxElement();
    const refTitle = useLaxElement();
    const refImageImplantation = useLaxElement();
    const refImageAblation = useLaxElement();

    return (
        <Scene>
            <ColorBackground
                ref={refColorBackground}
                data-lax-opacity={`(${r(-10)}-vh) 0, (${r(0)}-vh) 1, ${r(0)} 1, ${r(250)} 0`}
            />
            <Title
                ref={refTitle}
                data-lax-opacity={`${r(-100)} 0, ${r(0)} 1, ${r(300)} 1, ${r(400)} 0`}
                data-lax-translate-y={`${r(-1000)} -1000, ${r(200)} 200`}
                dangerouslySetInnerHTML={{ __html: title.html }}
            />
            <ImageImplantation
                ref={refImageImplantation}
                data-lax-opacity={`${r(-100)} 0, ${r(0)} 1, (${r(600)}+vh) 1, (${r(610)}+vh) 0`}
                data-lax-translate-y={`${r(-1000)} 200, ${r(1000)} -200`}
                src="/images/scene-6-implantation.jpg"
                ratio={1075 / 750}
            />
            <TextImplatation dangerouslySetInnerHTML={{ __html: text_implantation.html }} />
            <ImagePacemaker src="/images/scene-6-pacemaker.jpg" ratio={1} />
            <ImageAblation
                ref={refImageAblation}
                data-lax-opacity={`${r(500)} 0, ${r(600)} 1, (${r(1800)}+vh) 1, (${r(1810)}+vh) 0`}
                data-lax-translate-y={`${r(-200)} -200, ${r(1800)} 200`}
                src="/images/scene-6-ablation.jpg"
                ratio={1125 / 784}
            />
            <TextAblation dangerouslySetInnerHTML={{ __html: text_ablation.html }} />
        </Scene>
    );
};

export default Scene6;

const Scene = styled.div`
    position: relative;
    height: 3000px;
    background: ${props => props.theme.colors.white};
`;

const ColorBackground = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: ${props => props.theme.colors.blueLight};
    ${gutterBackground}
`;

const Title = styled.div`
    position: absolute;
    top: 100px;
    left: ${sizeAsBase(2)};

    h2 {
        ${h0}
    }

    ${media.large`
        left: ${sizeAsMax(2)};
    `}
`;

const ImagePacemaker = styled(BoxImageSized)`
    position: absolute;
    top: 600px;
    margin-top: calc(${sizeFromRow(600)} + (600px - ${sizeFromRow(400)}) / 2);
    width: ${sizeFromRow(400)};
    left: ${sizeFromRow(860 / 2)};
    margin-left: ${sizeFromRow(-200)};
    border-radius: 50%;
    overflow: hidden;
`;

const ImageImplantation = styled(BoxImageSized)`
    position: absolute;
    left: 0;
    top: 600px;
    width: ${sizeFromRow(860)};
    mix-blend-mode: multiply;
`;

const TextImplatation = styled(RteMedicalCare)`
    position: absolute;
    right: ${sizeAsBase(2)};
    top: 600px;
    width: ${sizeFromRow(360)};
`;

const ImageAblation = styled(BoxImageSized)`
    position: absolute;
    top: 1800px;
    right: 0;
    width: ${sizeFromRow(900)};
    mix-blend-mode: multiply;
`;

const TextAblation = styled(RteMedicalCare)`
    position: absolute;
    left: ${sizeAsBase(2)};
    top: 2000px;
    width: ${sizeFromRow(360)};
`;
