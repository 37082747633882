import React, { forwardRef } from 'react';
import styled from 'styled-components';

const Box = styled.div`
    position: relative;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &:before {
        content: '';
        display: block;
        padding-bottom: ${props => (1 / props.ratio) * 100}%;
    }
`;

const BoxImageSized = ({ ratio, src, imgAttrs = {}, ...others }, ref) => {
    return (
        <Box {...others} ref={ref} ratio={ratio}>
            <img src={src} {...imgAttrs} />
        </Box>
    );
};

export default forwardRef(BoxImageSized);
