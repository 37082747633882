import React from 'react';
import styled from 'styled-components';
import BoxImageSized from '../BoxImageSized';
import { sizeFromRow, sizeAsBase, sizeAsMax } from '../../styles/utils';
import { useLaxElement } from '../../hooks/useLax';
import { h0 } from '../../styles/typography';
import { media } from '../../styles';
import { gutterBackground } from '../../styles/others';

const r = n => n + 6400 + 3500;

const Scene5 = ({ title, text }) => {
    const refTitle = useLaxElement();
    const refBackground = useLaxElement();
    const refCharacters = useLaxElement();

    return (
        <Scene>
            <Title
                ref={refTitle}
                /* eslint-disable-next-line */
                data-lax-opacity={`(${r(-160)}-elh) 0, (${r(-150)}-elh) 1, (${r(0)}-vh*0.5+900) 1, (${r(0)}-vh*0.5+1000) 0`}
                data-lax-translate-y={`(${r(0)}-vh*0.5-elh) (-vh*0.5-elh), ${r(0)} 0`}
                dangerouslySetInnerHTML={{ __html: title.html }}
            />
            <Background
                ref={refBackground}
                data-lax-opacity={`(${r(-10)}-vh) 0, (${r(0)}-vh) 1, ${r(1600)} 1, ${r(1601)} 0`}
                data-lax-translate-y={`(${r(0)}-vh) -500, (${r(0)}+vh) 400`}
                src="/images/scene-5-background.jpg"
                ratio={1360 / 953}
            />
            <Characters
                ref={refCharacters}
                data-lax-opacity={`(${r(-10)}-vh) 0, (${r(0)}-vh) 1, ${r(1600)} 1, ${r(1601)} 0`}
                data-lax-translate-y={`(${r(0)}-vh) -100, (${r(0)}+vh) 100`}
                src="/images/scene-5-characters.png"
                ratio={528 / 724}
            />
            <Rte dangerouslySetInnerHTML={{ __html: text.html }} />
        </Scene>
    );
};

export default Scene5;

const Scene = styled.div`
    position: relative;
    height: 1600px;
    background: #fffaea;
    ${gutterBackground}
`;

const Title = styled.div`
    position: absolute;
    top: 100px;
    left: ${sizeAsBase(2)};
    max-width: calc(100% - ${sizeAsBase(4)});

    h2 {
        ${h0}
    }

    ${media.large`
        left: ${sizeAsMax(2)};
    `}
`;

const Background = styled(BoxImageSized)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    mix-blend-mode: multiply;
`;

const Characters = styled(BoxImageSized)`
    position: absolute;
    top: 300px;
    left: ${sizeFromRow(50)};
    width: ${sizeFromRow(528)};
`;

const Rte = styled.div`
    position: relative;
    top: 1250px;
    width: ${sizeFromRow(540)};
    margin: 0 auto;
`;
